.App {
  text-align: center;
}

.cmodal {
  /* position: absolute;
  top: 50%; left: 50%;
  transform: translate(-50%, -50%); */
  width: clamp(300px, 90vw, 400px);
}

.spooky button::after {
  display: none !important;
}
:root {
  --active-color: #3498db;
}
.freq:after {
  content: " *";
  color: red;
}
@keyframes spin {
  0% {
    transform: rotateZ(0deg);
  }
  100% {
    transform: rotateZ(360deg);
  }
}
.spinner {
  animation: spin 1s infinite linear;
}
@keyframes sneeze {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
.blinky {
  animation: sneeze 0.4s infinite linear;
}
.thighlight i {
  color: var(--active-color);
}
.form-search-nest {
  position: absolute;
  z-index: 4;
  top: 100%;
  left: 0;
  // width: 100%;
  background-color: #000;
  display: flex; flex-flow: column;
  span.tip {
      color: #fefefe;
      padding: 10px;
      background-color: #1e1e1e;
    }
  .form-search-item {
    color: #fff;
    padding: 10px;
    opacity: 0.9;
    border: none; outline: none;
    background-color: #000;
    text-align: left;
    small {
      opacity: 0.5;
    }
    &:nth-child(odd) {
      background-color: #1e1e1e;
    }
    &:hover {
      opacity: 1;
    }
  }
}
.dashtable {
  tbody > tr:nth-child(even) {
    background-color: #e3e3e3;
  }
  tbody > tr:nth-child(odd) {
    background-color: #f3f3f3;
  }
  tbody > tr > th {
    font-weight: normal !important;
  }
}
.authform {
  form {
    padding: 15px; display: flex; gap: 10px;
    flex-flow: column;
  }
  a {
    text-align: center;
  }
  .footer {
    display: flex;
  }
  .footer > button {
    flex: 50%; border-radius: 0;
  }
  .head {
    background-color: #000;
    width: 100%; display: flex;
    justify-content: center;
    padding: 15px 0;
  }
}
#navnest {
  position: sticky; top: 0; z-index: 5;
  background-color: #3c8dbc;
  display: flex; flex-flow: row;
  min-height: 50px; align-items: center;
  .sideBtn {
    display: none;
    filter: invert(1);
    padding: 0 15px;
  }
}
#navnest.admin {
  background-color: #212121;
  h1, p {
    color: #fff;
  }
  i {
    color: #3c8dbc;
  }
}
@media screen and (max-width: 800px) {
  .fakeGrid {
    flex-wrap: wrap !important;
  }
}
@media screen and (max-width: 500px) {
  #navnest .sideBtn {
    display: block;
  }
  #navnest .icon {
    display: none;
  }
}
#navnest .icon {
  margin: 5px; margin-left: 15px;
  filter: invert(1);
}
#navnest h1 {
  margin:0;
}

#main {
  display: flex; justify-content: center; width: 100%;
  margin: 0 5vmin; margin-top: 10vmin;

  flex-flow: column; align-items: center;
  @media screen and (min-width: 500px) {
    &:not(.mobile) {
      margin-left: calc(5vmin + 135px);
    }
  }
}

.item-left {
  margin-right: auto;
}

.baseholder {
  display: flex; flex-flow: row; align-items: flex-start;
  @media screen and (max-width: 500px) {
    .collapsed {
      max-height: 0 !important;
    }
  }
}

#sidenav {
  position: fixed; top: 50px; z-index: 5;
  display: flex; flex-flow: column; flex-shrink: 0;
  background-color: #222d32;
  width: 100%; 
  transition: max-height 0.4s ease-out;
  overflow: hidden;
  &:not(.collapsed) {
    max-height: 100%;
  }
  @media screen and (min-width: 500px) {
    max-width: clamp(70px, 80vw, 300px);
    width: auto;
    height: 100vh;
    .collapse {
      display: none;
    }
  }
}

$fadeTime: 0.3s;
#sidenav button {
  padding: 25px 10px; display: flex; flex-flow: row; gap: 5px;
  align-items: center;
  background: none; border: none;

  $fade: 0.7;
  &[aria-active="true"] {
    i {color:  var(--active-color); opacity: 1;}
    p {opacity: 1;}
  }
  &:hover {
    background: rgba(255, 255, 255, 0.1);
    p {
      opacity: 1;
    }
  }
  i {
    font-size: 1.4rem; min-width: 30px; color: white; opacity: $fade;
  }
  p {
    margin: 0; width: 100%; text-align: left; color: white;
    opacity: $fade;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}
